<template>
  <div class="cultureMobile">
    <div class="cultureMobile__table">
      <div class="tableItem cultureMobile__table-choiceAll">
        <el-checkbox
          v-model="checkAll"
          class="tableItem__checkBox"
          :indeterminate="isIndeterminate"
          @change="handleCheckAllChange"
        >
          <span class="tableItem__content-title data__title">Выбрать все</span>
        </el-checkbox>

        <div
          v-if="checkedCultures.length > 1 && !isViewer"
          class="tableItem__removeItems"
          @click="handleTableMenu('controlTableRemoveSelectedItems')"
        >
          Удалить выбранные
        </div>
      </div>

      <el-checkbox-group
        v-model="checkedCultures"
        @change="handleCheckedCulturesChange"
      >
        <el-checkbox
          v-for="(item, index) in tableData"
          :key="index"
          :label="item"
          class="cultureMobile__table-list tableItem"
        >
          <div class="tableItem__content">
            <div class="tableItem__content-data">
              <span class="data__title">{{ item.name }}</span>

              <span class="data__date">
                {{ item.harvest_year }}&nbsp;сбор урожая
              </span>
            </div>

            <div v-if="!isViewer" class="tableItem__content-buttons">
              <IconPenMobile @onClick="handleEdit(item)" />
              <IconTrashMobile @onClick="handleDelete(item)" />
            </div>
          </div>
        </el-checkbox>
      </el-checkbox-group>
    </div>

    <div v-if="!isViewer" class="containerMobile">
      <BottomToolbarButton
        title="Добавить культуру"
        @button-clicked="handleTableMenu('controlTableAddItem')"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import BottomToolbarButton from '@/UI/form/buttons/BottomToolbarButton'
import IconPenMobile from '@/UI/icon/IconTrashMobile'
import IconTrashMobile from '@/UI/icon/IconPenMobile'

import { FETCH_CULTURES_PAGE } from '@/views/control/store/actions'

export default {
  name: 'ControlTableCultureMobile',
  components: { IconTrashMobile, IconPenMobile, BottomToolbarButton },
  props: {
    tableData: { type: Array, required: true },
  },
  data() {
    return {
      checkAll: false,
      isIndeterminate: true,
      checkedCultures: [],
      cultures: this.tableData,
    }
  },
  mounted() {
    this.fetchCultures()
  },
  methods: {
    ...mapActions({ culturesFetch: FETCH_CULTURES_PAGE }),
    fetchCultures() {
      this.culturesFetch(this.params)
    },
    emitCultures(val) {
      this.$emit('selectionChange', {
        ids: val.map(item => item.id),
        names: val.map(item => item.name),
        local_names:
          val.map(item => (item.local_name ? item.local_name : item.name)) ||
          [],
      })
    },
    handleCheckAllChange(val) {
      this.checkedCultures = val ? this.tableData : []
      this.isIndeterminate = false

      this.emitCultures(this.checkedCultures)
    },
    handleCheckedCulturesChange(val) {
      let checkedCount = val.length

      this.checkAll = checkedCount === this.cultures.length
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.cultures.length

      this.emitCultures(val)
    },
    handleEdit(row) {
      this.$emit('editRow', row)
    },
    handleDelete(row) {
      this.$emit('deleteRow', row)
    },
    handleTableMenu(command) {
      this.$emit('selectDropdownMenu', command)
    },
  },
}
</script>

<style lang="sass" scoped>
.tableItem
  display: flex
  align-items: center
  background: #FFFFFF
  padding: 12px 16px
  margin-bottom: 8px
  margin-right: 0
  border: 1px solid $greyBorder
  &__removeItems
    font-weight: 500
    font-size: 14px
    color: $color-red
  &__content
    display: flex
    align-items: center
    justify-content: space-between
    width: 100%
    &-data
      display: flex
      flex-direction: column
      justify-content: flex-start
      .data__date
        font-weight: 400
        font-size: 14px
        color: $fontColor
        margin-top: 2px
    &-buttons
      display: flex
      align-items: center
      justify-content: space-between
      column-gap: 12px

.data__title
  font-weight: 400
  font-size: 14px
.cultureMobile
  &__table
    margin-bottom: 60px
    &-choiceAll
      display: flex
      align-items: center
      justify-content: space-between
      column-gap: 16px
</style>
